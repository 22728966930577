<template>
  <div class="my-4 container mx-auto" style="min-height: 660px;">
    <el-card shadow="none">
      <el-tabs :tab-position="tabPostion">
        <el-tab-pane label="个人信息" class="pl-4">
          <el-form ref="infoForm" :model="infoForm" :rules="rulesInfo" class="lg:w-1/2 w-full">
            <el-form-item label="头像" class="flex">
              <div class="ml-4">
                <el-avatar :src="infoForm.avatar" :size="80"></el-avatar>
                <el-upload
                  :action="uploadUrl"
                  :headers="headers"
                  :name="'file'"
                  :accept="'image/png, image/jpeg, image/jpg, image/gif'"
                  :on-success="setAvatarVal"
                >
                  <el-button size="small" icon="el-icon-upload">上传头像</el-button>
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="账号">
              <el-input v-model="infoForm.username" disabled></el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="infoForm.nickname"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="infoLoading" @click="saveProfile">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="修改密码" class="px-4">
          <el-form ref="pwdForm" :model="pwdForm" :rules="rulesPwd" class="lg:w-1/2 w-full">
            <el-form-item label="旧密码" prop="oldPwd">
              <el-input v-model="pwdForm.oldPwd" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
              <el-input v-model="pwdForm.newPwd" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPwd">
              <el-input v-model="pwdForm.checkPwd" type="password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="pwdLoading" @click="updatePassword">修改</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>

import { isLaptop } from '@/utils/device'
import { getToken } from '@/utils/auth'

import { updateProfile, resetPassword } from '@/api/user'

export default {
  name: 'UcenterInfo',
  data() {
    const validateNickname = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入昵称'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('密码不能小于6个字符'))
      } else {
        callback()
      }
    }
    const validateCheckPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('密码不能小于6个字符'))
      } else if (value !== this.pwdForm.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      infoForm: {
        username: '',
        nickname: '',
        avatar: '',
      },
      infoLoading: false,
      pwdForm: {
        oldPwd: '',
        newPwd: '',
        checkPwd: ''
      },
      pwdLoading: false,
      tabPostion: 'left',
      headers: {},
      uploadUrl: '/file/upload',
      rulesInfo: {
        nickname: [
          { required: true, trigger: 'blur', validator: validateNickname }
        ],
      },
      rulesPwd: {
        oldPwd: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        newPwd: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ],
        checkPwd: [
          { required: true, trigger: 'blur', validator: validateCheckPassword }
        ],
      }
    }
  },
  mounted() {
    this.infoForm.nickname = this.$store.getters.name
    this.infoForm.username = this.$store.getters.username
    this.infoForm.avatar = this.$store.getters.avatar
    if (isLaptop()) {
      this.tabPostion = 'left'
    } else {
      this.tabPostion = 'top'
    }
    window.onresize = () => {
      if (isLaptop()) {
        this.tabPostion = 'left'
      } else {
        this.tabPostion = 'top'
      }
    }
    this.headers['Auth-Token'] = getToken()
    this.uploadUrl = `${process.env.VUE_APP_BASE_API}${this.uploadUrl}`
  },
  methods: {
    setAvatarVal(res) {
      this.infoForm.avatar = res.data.url
    },
    saveProfile() {
      this.$refs['infoForm'].validate(valid => {
        if (valid) {
          this.infoLoading = true
          const data = { 
            nickname: this.infoForm.nickname,
            avatar: this.infoForm.avatar
          }
          updateProfile(data).then(() => {
            this.infoLoading = false
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.$store.dispatch('user/updateInfo', data)
          }).catch(() => {
            setTimeout(() => {
              this.infoLoading = false
            }, 1500)
          })
        }
      })
    },
    updatePassword() {
      this.$refs['pwdForm'].validate(valid => {
        if (valid) {
          this.pwdLoading = true
          resetPassword({ 
            password: this.pwdForm.oldPwd,
            new_password: this.pwdForm.newPwd
          }).then(() => {
            this.pwdLoading = false
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          }).catch(() => {
            setTimeout(() => {
              this.pwdLoading = false
            }, 1500)
          })
        }
      })
    }
  }
}
</script>